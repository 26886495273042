import React, {useLayoutEffect} from "react";
import "./App.css";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import parse from "html-react-parser";

function copyLink(e) {
    deleteAnchorClass()
    const link = e.target.getAttribute("href")
    let text = document.location.protocol + "//" + document.location.host + link
    window.navigator.clipboard.writeText(text).then(r =>
        console.info('🔗 Link copied to clipboard')
    );
    alert('🔗 Ссылка скопирована.')
}

function deleteAnchorClass() {
    let elems = document.getElementsByClassName('anchor');
    for (let i = 0; i < elems.length; i++) {
        elems[i].classList.remove('anchor')
    }
}

function scrollToAnchor() {
    let el_id = document.location.hash.replace('#', '');
    console.info(el_id);
    if (el_id) {
        document.getElementById(el_id).classList.add("anchor")
        document.getElementById(el_id).scrollIntoView()
    }
}


function App(props) {
    useLayoutEffect(() => {
        scrollToAnchor();
    }, []);
    const podcast = props.podcast;
    const rows = [];

    rows.push(
        <div className="row" key="row0">
            <div key="column0" className="column0">
                <div className="logo">
                    <img src={podcast.image.url} title={'Добро пожаловать на ' + podcast.image.title}
                         alt={'Логотип подкаста ' + podcast.image.title}/>
                </div>
            </div>
            <div key="column1" className="column1">
                <p className="description">
                    Добро Пожаловать на «Садовую 36». Здесь мы рассказываем и обсуждаем интересные истории, по которым в
                    принципе можно было бы снимать крутые фильмы или сериалы. Но мы их не снимаем, а пишем классный
                    подкаст.
                    Если вам нравится наше шоу, вы можете поделиться им со своими друзьями или поддержать нас на <a
                    href="https://patreon.com/Hans_Avichy." title="Патреон Садовой,36">Патреоне</a>. Патроны «Садовой,
                    36» получают ранний доступ к эпизодам, интересное послешоу и возможность прямой связи с нами в
                    комментариях. Мы благодарны вам за поддержку и обещаем не остаться в долгу.
                    Приятного прослушивания!
                </p>

            </div>
        </div>
    );
    for (let i = 0; i < podcast.items.length; i++) {
        const item = podcast.items[i];
        const description = parse(item.content);
        rows.push(
            <div className="row" id={item.guid} key={"row" + rows.length}>
                <div key="column0" className="column0">
                    <h3 className="title-mobile">{item.title} <a name={item.guid} className="copyLink"
                                                                 onClick={copyLink}
                                                                 href={'#' + item.guid}
                                                                 title="Нажмите, что бы скопировать ссылку на выпуск">поделиться</a>
                    </h3>
                    <div className="Img">
                        <img src={item.itunes.image} alt={item.title}/>
                    </div>

                </div>
                <div key="column1" className="column1 description-column">
                    <h3 className="title-desktop">{item.title} <a name={item.guid} className="copyLink"
                                                                  onClick={copyLink}
                                                                  href={'#' + item.guid}
                                                                  title="Нажмите, что бы скопировать ссылку на выпуск">поделиться</a>
                    </h3>
                    <AudioPlayer src={item.enclosure.url}
                                 showJumpControls={false}
                                 customAdditionalControls={[]}
                    />

                    {description}
                </div>

            </div>
        );
    }
    return <div className="App">{rows}</div>;
}

export default App;