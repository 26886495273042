import React from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import RSSParser from "rss-parser/dist/rss-parser.min"
import {createRoot} from "react-dom/client";


const RSS_URL = "https://anchor.fm/s/143a5138/podcast/rss";
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

function fetchRss(onError, onSuccess) {
    fetch(RSS_URL, {mode: "cors"})
        .then((resp) => resp.text(), onError)
        .then((text) => {
            const parser = new RSSParser();
            parser.parseString(text, (err, feed) => {
                if (err) {
                    onError(err);
                    return;
                }
                onSuccess(feed);
            });
        }, onError);
}

fetchRss(
    (e) => console.log(e),
    (podcast) => {
        root.render(
            <React.StrictMode>
                <App podcast={podcast}/>
            </React.StrictMode>)


    }
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

